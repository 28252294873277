import { Accordion } from '@abyss/web/ui/Accordion';
import { Table } from '@abyss/web/ui/Table';
import { Link } from '@abyss/web/ui/Link';
import { ProcessingStatItem } from '../ProcessingStatsItem';

export const FCHTransCount = ({ onChildValueChange }) => {
  const handleFCSReport = (e) => {
    e.preventDefault();

    onChildValueChange('fldchgSum');
  };

  return (
    <ProcessingStatItem title="Field Change Transaction Count">
      <Table.Container>
        <Table.TableBody>
          <Table.Row>
            <Table.Cell>
              {
                <Link onClick={handleFCSReport} href="#">
                  View Field Change Summary
                </Link>
              }
            </Table.Cell>
          </Table.Row>
        </Table.TableBody>
      </Table.Container>
    </ProcessingStatItem>
  );
};

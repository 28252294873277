import { Link } from '@abyss/web/ui/Link';
import { Box } from '@abyss/web/ui/Box';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useCollapse } from '@abyss/web/hooks/useCollapse';

export const ProcessingStatItem = ({ title, children }) => {
  const { collapseProps, buttonProps, isOpen } = useCollapse({
    defaultIsOpen: false,
  });
  return (
    <>
      <Link
        {...buttonProps}
        css={{
          color: '$primary1',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '45px',
          fontSize: '$md',
          fontWeight: 'bold',
          borderBottom: '1px solid var(--abyss-colors-gray6)',
          padding: '0 20px',
        }}
      >
        {title}
        <IconMaterial
          icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          color="$interactive1"
          size="24px"
        />
      </Link>
      <div {...collapseProps}>
        <Box padding="$md" color="">
          {children}
        </Box>
      </div>
    </>
  );
};

import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Text } from '@abyss/web/ui/Text';
import { Layout } from '@abyss/web/ui/Layout';
import { useState } from 'react';
import { useGetTransSetStatusQuery } from '@src/store/services/StatusViewServices';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { formatDate } from '@src/utils';

const TransactionItem = ({
  children,
  paddingTop = '0',
  paddingBottom = '0',
}) => {
  return (
    <Grid.Col
      css={{
        paddingBottom,
        paddingTop,
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
      }}
      span={{
        xs: '100%',
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '100%',
      }}
    >
      {children}
    </Grid.Col>
  );
};

export const Monitor = () => {
  const [date, setDate] = useState(new Date());
  const { fileId } = useRouter().getRouteParams();

  const { data, refetch } = useGetTransSetStatusQuery(fileId);

  console.log('here is the id', fileId, data);

  const handleRefresh = () => {
    refetch();
    setDate(new Date());
  };

  return (
    <React.Fragment>
      <Router.MetaTags title="Home" />
      <Card
        header="Transaction Set Detail Monitor"
        css={{
          'abyss-card-title': {
            color: 'var(--abyss-colors-white)!important',
            backgroundColor: 'var(--abyss-colors-primary1)',
          },
        }}
      >
        <Card.Section>
          <Layout.Group alignLayout="center">
            <Grid
              css={{
                paddingBottom: '15px',
                paddingTop: '15px',
                justifyContent: 'center',
              }}
            >
              <TransactionItem paddingBottom="10px">
                <Text size="28px" fontWeight="bold">
                  Transaction Set Detail
                </Text>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Status at: </b>
                <Text>{formatDate(date)}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Transaction File Submitter: </b>
                <Text>{data?.submitterGroupName}</Text>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Transaction Set Submitter: </b>
                <Text>{data?.assignedSubmitterId}</Text>
              </TransactionItem>
              <TransactionItem>
                <b> Transaction Set Id: </b>
                <Text>{data?.transactionSetId}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Transaction File Id: </b>
                <Text>{data?.transactionFileId}</Text>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Business Process Id: </b>
                <Text>{data?.lastUpdatedId}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Set Created: </b>
                <Text>{formatDate(new Date(data?.setCreatedDateTime))}</Text>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Status: </b>
                <Text>{data?.statusTest}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Inbound Record Count: </b>
                <Text>{data?.inboundTransactionCount}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Source Record Count: </b>
                <Text>{data?.sourceTransactionCount}</Text>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Transaction Record Count: </b>
                <Text>{data?.transactionSetRecordCount}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Percent Family </b>
              </TransactionItem>
              <TransactionItem paddingBottom="10px">
                <b>Processing Complete:</b>
                <Text>{data?.workUnitCmpltPercentage}</Text>
              </TransactionItem>
              <TransactionItem>
                <b>Errors During</b>
              </TransactionItem>
              <TransactionItem paddingBottom="15px">
                <b>Family Processing:</b>
                <Text>{data?.workUnitErrors}</Text>
              </TransactionItem>
              <TransactionItem>
                <Button
                  size="sm"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  Refresh
                </Button>
              </TransactionItem>
            </Grid>
          </Layout.Group>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
};

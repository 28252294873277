import React, { useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { useGetParentFCHSummaryQuery } from '@src/store/services/ParentServices';
import { useGetFCHSummaryQuery } from '@src/store/services/ChildServices';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const FieldChangeSummary = ({ fileId, reportType, isChild, setId }) => {
  const { data: fieldChangeList, isLoading } = isChild
    ? useGetFCHSummaryQuery(setId)
    : useGetParentFCHSummaryQuery(fileId);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Field Type',
        accessor: 'fieldCategoryName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Field Code',
        accessor: 'fieldCode',
        canToggleVisibilty: true,
        isHiddenByDefault: true,
      },
      {
        Header: 'Data field',
        accessor: 'fieldName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Cell: ({ row }) => {
          return isChild ? (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link
                href={`/home/child-reports/${fileId}/${setId}/${reportType}/${row.values.fieldCode}`}
              >
                {row.values.fieldName.trim()}
              </Link>
            </Flex>
          ) : (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link
                href={`/home/reports/${fileId}/${reportType}/${row.values.fieldCode}`}
              >
                {row.values.fieldName.trim()}
              </Link>
            </Flex>
          );
        },
      },
      {
        Header: 'Field change count',
        accessor: 'successCount',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
    ],
    []
  );

  useEffect(() => {
    if (fieldChangeList) {
      dataTableProps.setData(fieldChangeList);
      console.log('field change list', fieldChangeList);
    }
  }, [fieldChangeList]);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    uniqueStorageId: 'data-table-usage',
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    removeFilterColumns: ['fieldCode'],
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
  });

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <DataTable hideTitleHeader tableState={dataTableProps} />
  );
};

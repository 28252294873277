import React from 'react';
import { Router } from '@abyss/web/ui/Router';

import { Monitor } from '@src/components/Monitor';
import { DetailView } from '@src/components/DetailView';
import { ErrorPage } from '@src/common/Error';
import useAuth from '@src/hooks/useAuth';
import { LoadingScreen } from '@src/common/LoadingScreen';
import { Logout } from '@src/components/Logout';
import { Reports } from '../components/Reports';
import { Home } from '../components/Home';
import { AppBody } from '../common/AppBody';

export const Routes = () => {
  const { isAuth, isLoading, error } = useAuth();
  if (isAuth === null || isLoading) {
    return <LoadingScreen fullscreen />;
  }
  return (
    <Router.Routes title="{{title}} | Prime">
      <Router.Route path="/logout" element={<AppBody isAuth={false} />}>
        <Router.Route index element={<Logout />} />
      </Router.Route>
      {isAuth ? (
        <React.Fragment>
          <Router.Route path="/" element={<AppBody isAuth />}>
            <Router.Route index element={<Home />} />
            <Router.Route
              path="/home/reports/:fileId/:reportType?/:fieldCode?"
              element={<Reports />}
            />
            <Router.Route
              path="/home/child-reports/:fileId/:setId/:reportType?/:fieldCode?"
              element={<Reports isChild />}
            />

            <Router.Route
              path="/home/detailView/:setId/:empId/:transId/:empname?/:fileRecdDt?/:custName?"
              element={<DetailView />}
            />

            <Router.Route path="logout" element={<Logout />} />
            <Router.Route path="*" element={<Home />} />
          </Router.Route>
          <Router.Route path="/home/monitor/:fileId" element={<Monitor />} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Router.Route path="/home/monitor/:fileId" element={<Monitor />} />
          <Router.Route path="*" element={<AppBody isAuth={isAuth} />}>
            <Router.Route path="*" element={<ErrorPage error={error} />} />
          </Router.Route>
        </React.Fragment>
      )}
    </Router.Routes>
  );
};

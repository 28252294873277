import { Table } from '@abyss/web/ui/Table';
import { Link } from '@abyss/web/ui/Link';
import { ProcessingStatItem } from '../ProcessingStatsItem';

export const ErrorAndWarningCount = ({ procStatsList, onChildValueChange }) => {
  const handleErrorsWarnings = (e) => {
    e.preventDefault();

    onChildValueChange('errWarSum');
  };
  return (
    <ProcessingStatItem title="Error And Warning Count">
      <Table.Container>
        <Table.TableBody>
          <Table.Row>
            <Table.Cell>
              <b>Customer Errors and Warnings</b>
            </Table.Cell>
            <Table.Cell>{procStatsList?.[0]?.errWarnCustCount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Eligibility Analyst Errors and Warnings</b>
            </Table.Cell>
            <Table.Cell>{procStatsList?.[0]?.errWarnEEACount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {
                <Link onClick={handleErrorsWarnings} href="#">
                  Total Errors and Warnings
                </Link>
              }
            </Table.Cell>
            <Table.Cell>
              {procStatsList?.[0]?.totalErrorsandWarnings}
            </Table.Cell>
          </Table.Row>
        </Table.TableBody>
      </Table.Container>
    </ProcessingStatItem>
  );
};

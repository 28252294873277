import { useLazyGetUserDetailsQuery } from '@src/store/services/LoginServices';
import { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const AUTH_KEY = 'type';

const useAuth = () => {
  const [isAuth, setIsAuth] = useState<null | boolean>(null);
  const router = useRouter();
  const location = router.getLocation();
  const [trigger, { data, isLoading, error }] = useLazyGetUserDetailsQuery();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlToken =
      urlParams.get(AUTH_KEY) || localStorage.getItem(AUTH_KEY) || '';
    if (urlToken) {
      trigger(urlToken);
      localStorage.setItem(AUTH_KEY, urlToken);
    }
  }, []);

  useEffect(() => {
    setIsAuth(!error && !!data);
  }, [error, data]);

  return {
    isAuth,
    data,
    error,
    isLoading,
  };
};

export default useAuth;

import { useEffect, useState } from 'react';
import { Text } from '@abyss/web/ui/Text';
import { AUTH_KEY } from '@src/hooks/useAuth';

const LOGOUT_TIMER = 3;

export const Logout = () => {
  const [timer, setTimer] = useState(LOGOUT_TIMER);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      localStorage.removeItem(AUTH_KEY);
      window.close();
    }
  }, [timer]);

  return (
    <Text
      css={{
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '$info1',
        display: 'flex',
        height: '50vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '40px',
      }}
    >
      You have successfully logged out of the Electronic Eligibility Management
      System.
      <br />
      For security reasons this window will close in {timer} seconds.
    </Text>
  );
};

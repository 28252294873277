import { Table } from '@abyss/web/ui/Table';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ProcessingStatItem } from '../ProcessingStatsItem';

export const ErrorTransCount = ({ procStatsList, onChildValueChange }) => {
  const handlSubReslnChanges = (e) => {
    e.preventDefault();

    onChildValueChange('subCorr');
  };
  const handleEligibilityAnalystChanges = (e) => {
    e.preventDefault();

    onChildValueChange('eligCorr');
  };

  return (
    <ProcessingStatItem title="Error Transaction Count">
      <Layout.Group grow>
        <Table.Container>
          <Table.TableBody>
            <Table.Row>
              <Table.Cell>
                <b>Adds Requiring Submitter Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.addErrCustCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Terms Submitted Requiring Submitter Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.trmErrCustCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Terms Requiring Submitter Resolution</b>
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.termsRequiringCustomerResolution}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Changes Requiring Submitter Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.chgErrCustCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Rejected Transactions Requiring Submitter Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.rejErrCustCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handlSubReslnChanges} href="#">
                    Total Submitter Resolution Transactions
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.totalRequiringCustomerResolution}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Total Transactions with Errors</b>
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.totalTranswithErrors}
              </Table.Cell>
            </Table.Row>
          </Table.TableBody>
        </Table.Container>
        <Table.Container>
          <Table.TableBody>
            <Table.Row>
              <Table.Cell>
                <b>Adds Requiring Eligibility Analyst Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.addErrEEACount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Terms Submitted Requiring Eligibility Analyst Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.trmErrEEACount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Terms Requiring Eligibility Analyst Resolution</b>
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.termsReqEligAnalystResln}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Changes Requiring Eligibility Analyst Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.chgErrEEACount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>
                  Rejected Transactions Requiring Eligibility Analyst Resolution
                </b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.rejErrEEACount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handleEligibilityAnalystChanges} href="#">
                    Total Eligibility Analyst Resolution Transactions
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.totalEligAnalystReslnTrans}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Transactions requiring Both Resolution</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.transreqBothResln}</Table.Cell>
            </Table.Row>
          </Table.TableBody>
        </Table.Container>
      </Layout.Group>
    </ProcessingStatItem>
  );
};

/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { SelectInput } from '@abyss/web/ui/SelectInput';

//import { BASE_URL } from '@src/store/services/ServiceApi';
import { getServiceURL } from '@src/env.config';

import { getCachedUserDetails } from '@src/utils';

const reportTypes = [
  {
    value: 'procStat',
    download: false,
    selected: true,
    label: 'Processing Statistics',
  },
  { value: 'addTran', reportType: 'ADD', label: 'Add Transactions' },
  { value: 'errWarSum', download: false, label: 'Error & Warning Summary' },
  { value: 'allTrans', reportType: 'AT', label: 'All Transactions' },
  { value: 'chgTran', reportType: 'CHG', label: 'Change Transactions' },
  {
    value: 'subCorr',
    reportType: 'EE',
    label: 'Submitter Corrections Required',
  },
  {
    value: 'eligCorr',
    reportType: 'IE',
    label: 'Eligibility Analyst Corrections Required',
  },
  { value: 'nochg', reportType: 'NCH', label: 'No Change Transactions' },
  { value: 'TermAutoChg', reportType: 'TRM', label: 'Terms Submitted' },
  { value: 'fldchgSum', reportType: 'FCH', label: 'Field Change Summary' },
  {
    value: 'memberOM',
    reportType: 'MOM',
    label: 'Member omission Transactions',
    isChild: false,
  },
];
const downloadReports = reportTypes.filter((r) => r.download !== false);

/**
 * Header
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterAndDownload = ({
  fileId,
  setId,
  reportType,
  fileDetail,
  onChange,
  isChild = false,
}) => {
  const [reports, setReports] = React.useState([]);
  const filteredReportTypes = reportTypes.filter((r) =>
    r.isChild === false ? !isChild : true
  );
  const handleReportChange = (value) => {
    onChange?.(value);
  };

  const handleDownload = (e) => {
    e.preventDefault();
    const { platform, username, userType, policyNbrs } = getCachedUserDetails();
    const baseFileName = `${
      isChild
        ? fileDetail?.customerName.trim()
        : fileDetail?.custSubGroup.trim()
    }_${fileDetail?.lastUpdateDate.trim()}`;
    reports.forEach((report) => {
      const filename = `${baseFileName}_${report}`.replace(
        /[\/+\.+\:+\,+\s+-]/g,
        '_'
      );
      const resolvedReport = reportTypes.find((x) => x.value === report);
      let downloadURL = `${getServiceURL()}`;

      if (isChild) {
        downloadURL = `${downloadURL}primeChildDownload/${resolvedReport?.reportType}?txnSetId=${setId}&transFileId=0&filename=${filename}&platform=${platform}&username=${username}&userType=${userType}`;
      } else {
        downloadURL = `${downloadURL}primeParentDownload/${resolvedReport?.reportType}?fileId=${fileId}&filename=${filename}&platform=${platform}&username=${username}&userType=${userType}`;
      }
      if (policyNbrs.length || true)
        downloadURL = `${downloadURL}&polNums=${policyNbrs}`;
      window.open(downloadURL);
    });
  };
  const reportTypeLabel =
    reportTypes?.find((x) => x.value === reportType)?.label || 'Report';

  return (
    <Grid>
      <Grid.Col span={{ xs: '50%', md: '50%', lg: '50%', xl: '50%' }}>
        <Layout.Group
          alignLayout="left"
          css={{
            label: {
              fontWeight: 'bold',
            },
          }}
        >
          {reportTypeLabel && <Router.MetaTags title={reportTypeLabel} />}
          <label>Select Report</label>
          <SelectInput
            css={{
              'abyss-select-input-root': {
                width: '350px',
              },
            }}
            placeholder="Pick one"
            isClearable
            isSearchable
            options={filteredReportTypes}
            value={reportType}
            validators={{ required: true }}
            onChange={handleReportChange}
          />
        </Layout.Group>
      </Grid.Col>
      <Grid.Col span={{ xs: '50%', md: '50%', lg: '50%', xl: '50%' }}>
        <Layout.Group alignLayout="right">
          <SelectInputMulti
            placeholder="Select One or More Reports"
            isSearchable
            value={reports}
            onChange={setReports}
            options={downloadReports}
            validators={{ required: true }}
          />
          <Button
            onClick={handleDownload}
            variant="outline"
            className="no-margin-left no-padding-left no-padding-right"
          >
            <strong>Download</strong>
          </Button>
        </Layout.Group>
      </Grid.Col>
    </Grid>
  );
};

import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import React, { useEffect, useState } from 'react';
import { getCachedUserDetails } from '@src/utils';

const searchTypeOptions = [
  { value: 'custName', label: 'Customer name' },
  { value: 'polNum', label: 'Policy number' },
  { value: 'subId', label: 'Submission by Name/ID' },
];
const fileProcessingOptions = [
  { value: '', label: 'All' },
  { value: '68', label: 'Prime NB Pending' },
  { value: '8', label: 'Threshold Exceeded' },
];
const colStyles = {
  paddingLeft: 0,
  display: 'flex',
  alignItems: 'flex-start',
  '&>*': {
    alignSelf: 'center',
  },
};
export const CustomHeader = ({ onClear, isLoading }) => {
  const [isInternal, setIsInternal] = useState(false);
  useEffect(() => {
    const { userType } = getCachedUserDetails();
    setIsInternal(userType === 'I');
  }, []);

  if (!isInternal) return null;
  return (
    <Grid
      css={{
        '.groupButton': {
          borderRadius: 0,
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          fontSize: '0.9rem',
          padding: '0 10px',
        },
        '.abyss-select-input-input-container, input': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          height: '100%',
        },
        '.abyss-select-input-label,.abyss-text-input-label': {
          display: 'none',
        },
      }}
    >
      <Grid.Col className="left" css={colStyles} span={3}>
        <Button className="groupButton">Search</Button>
        <TextInput placeholder="Search" model="searchFor" isClearable />
      </Grid.Col>
      <Grid.Col className="left" css={colStyles} span={3}>
        <Button className="groupButton">Search Type</Button>
        <SelectInput
          width="320px"
          placeholder="Search"
          model="searchType"
          isClearable
          isSearchable
          options={searchTypeOptions}
        />
      </Grid.Col>
      <Grid.Col className="left" css={colStyles} span={4}>
        <Button className="groupButton">File Processing Status</Button>
        <SelectInput
          width="250px"
          placeholder="All"
          model="fileProcessingStatus"
          isClearable
          isSearchable
          options={fileProcessingOptions}
        />
      </Grid.Col>
      <Grid.Col
        className="left"
        css={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          padding: 0,
        }}
        span={2}
      >
        <Button
          type="submit"
          size="$sm"
          isDisabled={isLoading}
          ariaDisablingLabel="filtering data"
        >
          Search
        </Button>
        <Button
          variant="destructive"
          type="submit"
          size="$sm"
          onClick={onClear}
          isDisabled={isLoading}
          ariaDisablingLabel="clearing data"
        >
          Clear
        </Button>
      </Grid.Col>
    </Grid>
  );
};

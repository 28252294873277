import { apiService } from './ServiceApi';
// Define a service using a base URL and expected endpoints

export const statusViewServices = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getTransSetStatus: builder.query({
      query: (txnSetId) => ({
        method: 'POST',
        url: `/statusview/transSetStatus`,
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
  }),
});

export const { useGetTransSetStatusQuery } = statusViewServices;

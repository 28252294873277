import { Table } from '@abyss/web/ui/Table';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ProcessingStatItem } from '../ProcessingStatsItem';

export const SuccessTransCount = ({ procStatsList, onChildValueChange }) => {
  const handleTranAdd = (e) => {
    e.preventDefault();

    onChildValueChange('addTran');
  };
  const handlTotalTerms = (e) => {
    e.preventDefault();

    onChildValueChange('TermAutoChg');
  };
  const handlTotalChanges = (e) => {
    e.preventDefault();

    onChildValueChange('chgTran');
  };
  const handleNoChanges = (e) => {
    e.preventDefault();

    onChildValueChange('nochg');
  };
  const handleAllChanges = (e) => {
    e.preventDefault();

    onChildValueChange('allTrans');
  };

  return (
    <ProcessingStatItem title="Successful Transaction Count">
      <Layout.Group grow>
        <Table.Container>
          <Table.TableBody>
            <Table.Row>
              <Table.Cell>
                <b>Subscriber Adds</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.subscrAddsCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Dependent Adds </b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.depsAddsCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                {
                  <Link onClick={handleTranAdd} href="#">
                    Total Adds
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.subscrAddsCount +
                  procStatsList?.[0]?.depsAddsCount}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Subscriber Terms</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.subscrTrmCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Dependent Terms</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.depTrmCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handlTotalTerms} href="#">
                    Total Terms
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.subscrTrmCount +
                  procStatsList?.[0]?.depTrmCount}
              </Table.Cell>
            </Table.Row>
          </Table.TableBody>
        </Table.Container>
        <Table.Container>
          <Table.TableBody>
            <Table.Row>
              <Table.Cell>
                <b>Address</b>{' '}
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.adrChgCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Coverage</b>
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.covChgCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>Demographic</b>{' '}
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.demoChgCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handlTotalChanges} href="#">
                    Total Changes
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.demoChgCount +
                  procStatsList?.[0]?.covChgCount +
                  procStatsList?.[0]?.adrChgCount}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handleNoChanges} href="#">
                    No Changes
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>{procStatsList?.[0]?.noChangeCount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {
                  <Link onClick={handleAllChanges} href="#">
                    Total
                  </Link>
                }
              </Table.Cell>
              <Table.Cell>
                {procStatsList?.[0]?.subscrAddsCount +
                  procStatsList?.[0]?.depsAddsCount +
                  procStatsList?.[0]?.subscrTrmCount +
                  procStatsList?.[0]?.depTrmCount +
                  procStatsList?.[0]?.demoChgCount +
                  procStatsList?.[0]?.covChgCount +
                  procStatsList?.[0]?.adrChgCount +
                  procStatsList?.[0]?.noChangeCount}
              </Table.Cell>
            </Table.Row>
          </Table.TableBody>
        </Table.Container>
      </Layout.Group>
    </ProcessingStatItem>
  );
};

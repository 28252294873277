import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';

import React, { useEffect } from 'react';

export const DetailViewTable = ({ detailViewList }) => {
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Field Name',
        accessor: 'fieldName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        width: 350,
        label: 'Field Name',
        Cell: ({ row }) => {
          return row?.original?.isHighlight ? (
            <span style={{ backgroundColor: 'yellow' }}>
              {row?.original?.fieldName || ''}
            </span>
          ) : (
            row?.original?.fieldName || ''
          );
        },
      },

      {
        Header: 'Data Prior To Processing',
        accessor: 'dataBeforeProcessing',
        width: 350,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Data Prior To Processing',
        Cell: ({ row }) => {
          return row?.original?.isHighlight ? (
            <span style={{ backgroundColor: 'yellow' }}>
              {row?.original?.dataBeforeProcessing || ''}
            </span>
          ) : (
            row?.original?.dataBeforeProcessing || ''
          );
        },
      },
      {
        Header: 'Incoming Customer Data',
        accessor: 'incomingCustomerData',
        canToggleVisibilty: true,
        width: 350,
        isHiddenByDefault: false,
        label: 'Incoming Customer Data',
        Cell: ({ row }) => {
          return row?.original?.isHighlight ? (
            <span style={{ backgroundColor: 'yellow' }}>
              {row?.original?.incomingCustomerData || ''}
            </span>
          ) : (
            row?.original?.incomingCustomerData || ''
          );
        },
      },

      {
        Header: 'Data After Processing',
        accessor: 'dataAfterProcessing',
        canToggleVisibilty: true,
        width: 350,
        isHiddenByDefault: false,
        label: 'Data After Processing',
        Cell: ({ row }) => {
          return row?.original?.isHighlight ? (
            <span style={{ backgroundColor: 'yellow' }}>
              {row?.original?.dataAfterProcessing || ''}
            </span>
          ) : (
            row?.original?.dataAfterProcessing || ''
          );
        },
      },
    ];
  }, []);

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    showPagination: false,

    showTableSettings: false,
    showColumnSort: false,
  });
  function camelCaseToTitleCase(camelCase) {
    if (!camelCase) return '';
    const result = camelCase?.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  function maskSSN(ssn) {
    // return String(ssn?.replace(/\d(?=\d{4})/g, '*'));
    return String(ssn);
  }
  useEffect(() => {
    if (detailViewList) {
      let arr: any[] = [];
      arr = [...arr, detailViewList];

      const modifiedArr: any[] = [];
      const keys = Object.keys(arr[0]?.dataAfterProcessing);
      const valuesAfter = Object.values(arr[0]?.dataAfterProcessing) as any[];
      const valuesBefore = Object.values(
        arr[0]?.dataPriorToProcessing
      ) as any[];
      const incomingCustomerData = Object.values(
        arr[0]?.incomingCustomerData
      ) as any[];

      for (let num = 0; num < keys.length; num++) {
        if (
          keys[num] != 'address' &&
          keys[num] != 'coverages' &&
          keys[num] != 'policyamnt' &&
          keys[num] != 'docssup' &&
          keys[num] != 'authresp' &&
          keys[num] != 'broker' &&
          keys[num] != 'provider' &&
          keys[num] != 'sponsor' &&
          keys[num] != 'gpsMemberAddressList' &&
          keys[num] != 'gpsMemberContactList'
        ) {
          console.log('current value', keys[num]);
          var datAftProcValue;
          var datBefProcValue;
          var incomCustData;
          if (
            camelCaseToTitleCase(keys[num]).includes('Social Security Number')
          ) {
            console.log('Social security number', keys[num]);

            datAftProcValue =
              maskSSN(valuesAfter[num]) !== 'undefined'
                ? maskSSN(valuesAfter[num])
                : '';
            datBefProcValue =
              maskSSN(valuesBefore[num]) !== 'undefined'
                ? maskSSN(valuesBefore[num])
                : '';
            incomCustData =
              maskSSN(incomingCustomerData[num]) !== 'undefined'
                ? maskSSN(incomingCustomerData[num])
                : '';
          } else {
            datAftProcValue = valuesAfter[num];
            datBefProcValue = valuesBefore[num];
            incomCustData = incomingCustomerData[num];
          }
          modifiedArr.push({
            fieldName: camelCaseToTitleCase(keys[num]),
            dataAfterProcessing: datAftProcValue,
            dataBeforeProcessing: datBefProcValue,
            incomingCustomerData: incomCustData,
            isHighlight: !!(
              (
                (datAftProcValue?.length > 0 && // Check if datAftProcValue has a length greater than 0
                  datBefProcValue?.length > 0 && // Check if datBefProcValue has a length greater than 0
                  datAftProcValue?.trim().toLowerCase() !== // Check if trimmed and lowercased datAftProcValue is not equal to
                    datBefProcValue?.trim().toLowerCase()) || // trimmed and lowercased datBefProcValue
                ((datBefProcValue == null ||
                  datBefProcValue.trim().length === 0) && // Check if datBefProcValue is null or empty
                  datAftProcValue != null &&
                  datAftProcValue.trim().length > 0)
              ) // Check if datAftProcValue is not null and has a length greater than 0
            ),
          });
        } else if (keys[num] == 'address') {
          const addressArrayAfter = valuesAfter[num];
          const addressArrayBefore = valuesBefore[num];
          const addressArrayIncoming = incomingCustomerData[num];
          // console.log('addressArrayAfter', addressArrayAfter);
          for (let i = 0; i < addressArrayAfter?.length; i++) {
            const addressKeys = Object.keys(addressArrayAfter[i]);
            const addressValuesAfter =
              addressArrayAfter && addressArrayAfter[i]
                ? Object?.values(addressArrayAfter[i])
                : null;
            const addressValuesBefore =
              addressArrayBefore && addressArrayBefore[i]
                ? Object?.values(addressArrayBefore[i])
                : null;
            const addressValuesIncoming =
              addressArrayIncoming && addressArrayIncoming[i]
                ? Object?.values(addressArrayIncoming[i])
                : null;

            for (let j = 0; j < addressKeys?.length; j++) {
              modifiedArr.push({
                fieldName: camelCaseToTitleCase(addressKeys[j]),
                dataAfterProcessing: addressValuesAfter
                  ? (addressValuesAfter[j] as string)
                  : null,

                dataBeforeProcessing: addressValuesBefore
                  ? (addressValuesBefore[j] as string)
                  : null,
                incomingCustomerData: addressValuesIncoming
                  ? (addressValuesIncoming[j] as string)
                  : null,
                isHighlight: !!(
                  (
                    (addressValuesAfter != null && // Check if addressValuesAfter is not null
                      addressValuesBefore != null && // Check if addressValuesBefore is not null
                      (addressValuesAfter as string[])[j]?.length > 0 && // Check if the j-th element of addressValuesAfter has a length greater than 0
                      (addressValuesBefore as string[])[j]?.length > 0 && // Check if the j-th element of addressValuesBefore has a length greater than 0
                      (addressValuesAfter as string[])[j]
                        ?.trim()
                        .toLowerCase() !== // Check if the trimmed and lowercased j-th element of addressValuesAfter is not equal to
                        (addressValuesBefore as string[])[j]
                          ?.trim()
                          .toLowerCase()) || // the trimmed and lowercased j-th element of addressValuesBefore
                    ((addressValuesBefore == null ||
                      (addressValuesBefore as string[])[j]?.trim().length ===
                        0) && // Check if addressValuesBefore is null or empty
                      (addressValuesAfter as string[])[j]?.length > 0)
                  ) // Check if the j-th element of addressValuesAfter has a length greater than 0
                ),
              });
            }
          }
        } else if (keys[num] == 'coverages') {
          const subArrayAfter = valuesAfter[num];
          const subArrayBefore = valuesBefore[num];
          const subArrayIncoming = incomingCustomerData[num];

          for (let i = 0; i < subArrayAfter?.length; i++) {
            const subKeys = Object.keys(subArrayAfter[i]);
            const subValueAfter =
              subArrayAfter && subArrayAfter[i]
                ? Object?.values(subArrayAfter[i])
                : null;
            const subValueBefore =
              subArrayBefore && subArrayBefore[i]
                ? Object.values(subArrayBefore[i])
                : null;
            const subValuesIncoming =
              subArrayIncoming && subArrayIncoming[i]
                ? Object?.values(subArrayIncoming[i])
                : null;

            // Check if all values are null or trimmed length is zero
            const allValuesNull =
              subValueAfter?.every((value) => {
                return value === null || value.trim().length === 0;
              }) &&
              subValueBefore?.every((value) => {
                return value === null || value.trim().length === 0;
              }) &&
              subValuesIncoming?.every((value) => {
                return value === null || value.trim().length === 0;
              });

            if (allValuesNull) {
              continue; // Skip this entry if all values are null or trimmed length is zero
            }

            for (let j = 0; j < subKeys?.length; j++) {
              modifiedArr.push({
                fieldName: camelCaseToTitleCase(subKeys[j]),
                dataAfterProcessing: subValueAfter
                  ? (subValueAfter as string[])[j]
                  : null,
                dataBeforeProcessing: subValueBefore
                  ? (subValueBefore as string[])[j]
                  : null,
                incomingCustomerData: subValuesIncoming
                  ? (subValuesIncoming as string[])[j]
                  : null,
                isHighlight: !!(
                  (subValueAfter?.[j]?.length > 0 &&
                    subValueBefore?.[j]?.length > 0 &&
                    subValueAfter?.[j]?.trim().toLowerCase() !==
                      subValueBefore?.[j]?.trim().toLowerCase()) ||
                  ((subValueBefore == null ||
                    subValueBefore[j]?.trim().length === 0) &&
                    subValueAfter != null &&
                    subValueAfter[j]?.trim().length > 0)
                ),
              });
            }
          }
        } else if (
          keys[num] == 'coverages' ||
          keys[num] == 'policyamnt' ||
          keys[num] == 'docssup' ||
          keys[num] == 'authresp' ||
          keys[num] == 'provider' ||
          keys[num] == 'sponsor' ||
          keys[num] == 'broker' ||
          keys[num] == 'gpsMemberAddressList' ||
          keys[num] == 'gpsMemberContactList'
        ) {
          const subArrayAfter = valuesAfter[num];
          const subArrayBefore = valuesBefore[num];
          const subArrayIncoming = incomingCustomerData[num];
          // console.log('addressArrayAfter', addressArrayAfter);
          for (let i = 0; i < subArrayAfter?.length; i++) {
            const subKeys = Object.keys(subArrayAfter[i]);
            const subValueAfter =
              subArrayAfter && subArrayAfter[i]
                ? Object?.values(subArrayAfter[i])
                : null;
            console.log('subarrayBefore', subArrayBefore);
            const subValueBefore =
              subArrayBefore && subArrayBefore[i]
                ? Object.values(subArrayBefore[i])
                : null;

            const subValuesIncoming =
              subArrayIncoming && subArrayIncoming[i]
                ? Object?.values(subArrayIncoming[i])
                : null;

            for (let j = 0; j < subKeys?.length; j++) {
              modifiedArr.push({
                fieldName: camelCaseToTitleCase(subKeys[j]),
                dataAfterProcessing: subValueAfter
                  ? (subValueAfter as string[])[j]
                  : null,
                dataBeforeProcessing: subValueBefore
                  ? (subValueBefore as string[])[j]
                  : null,
                incomingCustomerData: subValuesIncoming
                  ? (subValuesIncoming as string[])[j]
                  : null,
                isHighlight: !!(
                  (
                    (subValueAfter?.[j]?.length > 0 && // Check if subValueAfter[j] has a length greater than 0
                      subValueBefore?.[j]?.length > 0 && // Check if subValueBefore[j] has a length greater than 0
                      subValueAfter?.[j]?.trim().toLowerCase() !== // Check if trimmed and lowercased subValueAfter[j] is not equal to
                        subValueBefore?.[j]?.trim().toLowerCase()) || // trimmed and lowercased subValueBefore[j]
                    ((subValueBefore == null ||
                      subValueBefore[j]?.trim().length === 0) && // Check if subValueBefore[j] is null or empty
                      subValueAfter != null &&
                      subValueAfter[j]?.trim().length > 0)
                  ) // Check if subValueAfter[j] is not null and has a length greater than 0
                ),
              });
            }
          }
        }
      }

      dataTableProps.setData(modifiedArr);
      // console.log('detail view data', detailViewList);
    }
  }, [detailViewList]);

  return (
    <React.Fragment>
      <DataTable hideTitleHeader tableState={dataTableProps} />
    </React.Fragment>
  );
};

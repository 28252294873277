import { configureStore } from '@reduxjs/toolkit';
import { apiService } from './services/ServiceApi';
import Filesreducer from '../store/FilesSlice';
export const appStore = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    files: Filesreducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiService.middleware),
});
export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Header } from '../Header';

export const AppBody = ({ isAuth = false }: { isAuth: null | boolean }) => {
  console.log('App body logger');
  return (
    <React.Fragment>
      <Header isAuth={isAuth} />
      <Router.Outlet />
    </React.Fragment>
  );
};

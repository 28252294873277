import React, { useEffect } from 'react';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { RecordCount } from './RecordCount';
import { FCHTransCount } from './FCHTransCount';
import { ErrorAndWarningCount } from './ErrorAndWarningCount';
import { ErrorTransCount } from './ErrorTransCount';
import { SuccessTransCount } from './SuccessTransCount';
import { useGetProcStatsQuery } from '@src/store/services/ParentServices';
import { useGetChildProcStatsQuery } from '@src/store/services/ChildServices';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const ProcessingStats = ({
  fileId,
  setId: txnSetId,
  onChildValueChange,
  isChild,
}) => {
  const { data: procStatsList, isLoading } = isChild
    ? useGetChildProcStatsQuery({ txnSetId, fileId })
    : useGetProcStatsQuery(fileId);

  /*  const [openAccordians, setOpenAccordians] = useState([
    'errAndWarningCount',
    'errTransCount',
    'fieldChangeTransCount',
    'recordCount',
    'transCount',
    'recordCount',
  ]); */

  useEffect(() => {
    if (procStatsList) {
      console.log('proc stats list', procStatsList);
    }
  }, [procStatsList]);
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CollapseProvider>
      <CollapseProvider.Button
        css={{
          'abyss-collapse-button-root': {
            display: 'flex',
            minHeight: '45px',
            padding: '0 20px',
            justifyContent: 'space-between',
            '.abyss-text-root': {
              color: '$primary1',
              fontSize: '$lg',
              fontWeight: 'bold',
            },
          },
        }}
      />
      <RecordCount procStatsList={procStatsList} />
      <SuccessTransCount
        procStatsList={procStatsList}
        onChildValueChange={onChildValueChange}
      />
      <ErrorTransCount
        procStatsList={procStatsList}
        onChildValueChange={onChildValueChange}
      />
      <ErrorAndWarningCount
        procStatsList={procStatsList}
        onChildValueChange={onChildValueChange}
      />
      <FCHTransCount onChildValueChange={onChildValueChange} />
    </CollapseProvider>
  );
};

import { apiService } from './ServiceApi';
export const ChildService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getChildFileDetails: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: '/newPrimeChildReport/getFileDetails',
        params: {
          fileId: '0',
          txnSetId: txnSetId,
        },
      }),
    }),

    getChildInternalERWSummary: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getChildInternalERWSummary`,
        params: {
          txnSetId: txnSetId,
          m_errType: ' ',
        },
      }),
    }),

    getChildExternalERWSummary: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: '/newPrimeChildReport/getChildExternalERWSummary',
        params: {
          txnSetId: txnSetId,

          m_errType: ' ',
        },
      }),
    }),
    getChildCustCorrect: builder.query({
      query: (pData) => ({
        method: 'GET',
        url: '/newPrimeChildReport/getChildCustCorrect',
        params: {
          txnSetId: pData.txnSetId,

          m_errType: pData.m_errType,
        },
      }),
    }),

    getChildEligibility: builder.query({
      query: (pData) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getChildEligibility`,
        params: {
          txnSetId: pData.txnSetId,

          m_errType: pData.m_errType,
        },
      }),
    }),
    getChildAllTrans: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: '/newPrimeChildReport/getChildAllTrans',
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
    getChildAddTrans: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: '/newPrimeChildReport/getChildAddTrans',
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
    getChildChangeTrans: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getChildChangeTrans`,
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
    getChildNoChange: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getChildNoChange`,
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
    getFCHSummary: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getFCHSummary`,
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),

    getFCHSummaryDetails: builder.query({
      query: (pData) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getFCHSummaryDetails`,
        params: {
          txnSetId: pData.txnSetId,

          m_fieldCode: pData.m_fieldCode,
        },
      }),
    }),
    getChildProcStats: builder.query({
      query: (pData) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getProcStats`,
        params: {
          fileId: pData.fileId,
          txnSetId: pData.txnSetId,
        },
      }),
    }),
    getChildTerms: builder.query({
      query: (txnSetId) => ({
        method: 'GET',
        url: `/newPrimeChildReport/getChildTerms`,
        params: {
          txnSetId: txnSetId,
        },
      }),
    }),
  }),
});

export const {
  useGetChildAddTransQuery,
  useGetChildAllTransQuery,
  useGetChildChangeTransQuery,
  useGetChildCustCorrectQuery,
  useGetChildEligibilityQuery,
  useGetChildExternalERWSummaryQuery,
  useGetChildFileDetailsQuery,
  useGetChildInternalERWSummaryQuery,
  useGetChildNoChangeQuery,
  useGetFCHSummaryDetailsQuery,
  useGetFCHSummaryQuery,
  useGetChildProcStatsQuery,
  useGetChildTermsQuery,
} = ChildService;

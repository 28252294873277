import React, { useState, useEffect } from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Layout } from '@abyss/web/ui/Layout';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useGetDetailViewQuery } from '@src/store/services/ParentServices';
import { LoadingScreen } from '@src/common/LoadingScreen';
import { DetailViewTable } from './DetailViewTable';

export const DetailView = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { setId, empId, transId, empname, fileRecdDt, custName } =
    useRouter().getRouteParams();

  const { data: detailViewList } = useGetDetailViewQuery({
    transSetId: setId,
    empId: `${empId}`,
    platform: 0,
    transId,
  });

  useEffect(() => {
    if (detailViewList) {
      console.log('Detail view list', detailViewList);
      setIsLoading(false);
    }
  }, [detailViewList]);
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <React.Fragment>
      <Router.MetaTags title="Detail View" />
      <Layout.Stack
        css={{
          paddingTop: '40px',
          td: {
            backgroundColor: '#deebf4',
          },
        }}
        alignLayout="center"
      >
        <Layout.Stack
          css={{
            alignItems: 'flex-start',
            maxWidth: 'var(--abyss-sizes-xl)',
          }}
        >
          <Grid
            css={{
              width: '100%',
              paddingBottom: '10px',
            }}
          >
            <Grid.Col span="100%">
              <Text
                css={{
                  color: '$primary1',
                  h1: {
                    margin: '0',
                  },
                }}
              >
                <h1>Detail View</h1>
              </Text>
            </Grid.Col>
            <Grid.Col span="100%">
              <Text>
                <b>Customer Submission group: </b> {custName}
              </Text>
              <br />
              <Text>
                <b>Processing Date: </b> {fileRecdDt}
              </Text>
              <br />
              <Text>
                <b>Name: </b> {empname}
              </Text>
            </Grid.Col>
          </Grid>
          <DetailViewTable detailViewList={detailViewList} />
        </Layout.Stack>
      </Layout.Stack>
    </React.Fragment>
  );
};

import { CACHE_KEY } from '@src/utils';
import { apiService } from './ServiceApi';

const loginService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: (eesUserId) => ({
        method: 'GET',
        url: 'getJwtUserAccess',
        params: {
          jwtToken: eesUserId,
          isAuth: true,
        },
      }),
      transformResponse: (response: any) => {
        // Cache user details in localStorage
        if (response?.user) {
          localStorage.setItem(CACHE_KEY, JSON.stringify(response?.user));
        }
        return response;
      },
    }),
  }),
});

export const { useGetUserDetailsQuery, useLazyGetUserDetailsQuery } =
  loginService;

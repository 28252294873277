import { Table } from '@abyss/web/ui/Table';
import { Card } from '@abyss/web/ui/Card';
import { Layout } from '@abyss/web/ui/Layout';

export const FileDetails = ({ fileDetail, isChild }) => {
  return (
    <Card header="File Details">
      <Card.Section>
        <Layout.Group grow>
          <Table.Container>
            <Table.TableBody>
              <Table.Row>
                <Table.Cell>
                  <strong>Customer Submission Group </strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.custSubGroup}</Table.Cell>
              </Table.Row>
              {isChild ? (
                <>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Policy Number</strong>
                    </Table.Cell>
                    <Table.Cell>{fileDetail?.policyNumber}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <strong>Customer Name</strong>
                    </Table.Cell>
                    <Table.Cell>{fileDetail?.customerName}</Table.Cell>
                  </Table.Row>
                </>
              ) : null}
              <Table.Row>
                <Table.Cell>
                  <strong>Customer File Name </strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.fname}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>File Identifier </strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.transFileId}</Table.Cell>
              </Table.Row>
              {isChild ? null : (
                <Table.Row>
                  <Table.Cell>
                    <strong>File Received Date</strong>
                  </Table.Cell>
                  <Table.Cell>{fileDetail?.fileRecieveDate}</Table.Cell>
                </Table.Row>
              )}
            </Table.TableBody>
          </Table.Container>
          <Table.Container>
            <Table.TableBody>
              {isChild ? (
                <Table.Row>
                  <Table.Cell>
                    <strong>File Received Date</strong>
                  </Table.Cell>
                  <Table.Cell>{fileDetail?.fileRecieveDate}</Table.Cell>
                </Table.Row>
              ) : null}
              <Table.Row>
                <Table.Cell>
                  <strong>Processing Date </strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.lastUpdateDate}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Profile Version</strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.prflVerNbr}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Processing Status </strong>
                </Table.Cell>
                <Table.Cell>{fileDetail?.processingStatus}</Table.Cell>
              </Table.Row>
              {isChild ? null : (
                <Table.Row>
                  <Table.Cell>
                    <strong>Policy Count</strong>
                  </Table.Cell>
                  <Table.Cell>{fileDetail?.policyCount}</Table.Cell>
                </Table.Row>
              )}
            </Table.TableBody>
          </Table.Container>
        </Layout.Group>
      </Card.Section>
    </Card>
  );
};

import React from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Card } from '@abyss/web/ui/Card';
import {
  useGetExternalERWSummaryQuery,
  useGetInternalERWSummaryQuery,
} from '@src/store/services/ParentServices';
import {
  useGetChildExternalERWSummaryQuery,
  useGetChildInternalERWSummaryQuery,
} from '@src/store/services/ChildServices';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const ErrorWarningSummary = ({ fileId, setId, isChild }) => {
  const { data: summaryData, isLoading: submitterLoading } = isChild
    ? useGetChildExternalERWSummaryQuery(setId)
    : useGetExternalERWSummaryQuery(fileId);
  const { data: elegibilityData, isLoading: elegibilityLoading } = isChild
    ? useGetChildInternalERWSummaryQuery(setId)
    : useGetInternalERWSummaryQuery(fileId);

  const submitterColumns = React.useMemo(
    () => [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Footer: () => {
          return <Text css={{ color: '$primary1' }}></Text>;
        },
      },
      {
        Header: 'Error and Warning Name',
        accessor: 'errorName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Customer Name',
        Cell: ({ row, value }) => {
          return (
            <Link
              href={`/home/${isChild ? 'child-reports' : 'reports'}/${fileId}${
                isChild ? '/' + setId : ''
              }/subCorr/${row.original.errType}`}
            >
              {value}
            </Link>
          );
        },
        Footer: (info) => {
          return <Text css={{ color: '$primary1' }}>Total</Text>;
        },
      },
      {
        Header: 'Count',
        accessor: 'count',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.count + sum, 0),
            [info.rows]
          );
          return <Text css={{ color: '$primary1' }}>{total}</Text>;
        },
      },
    ],
    [fileId]
  );

  const elegibilityColumns = React.useMemo(
    () => [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Error and Warning Name',
        accessor: 'errorName',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Customer Name',
        Cell: ({ row, value }) => {
          return (
            <Link
              href={`/home/${isChild ? 'child-reports' : 'reports'}/${fileId}${
                isChild ? '/' + setId : ''
              }/eligCorr/${row.original.errType}`}
            >
              {value}
            </Link>
          );
        },
        Footer: () => {
          return <Text css={{ color: '$primary1' }}>Total</Text>;
        },
      },
      {
        Header: 'Count',
        accessor: 'count',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.count + sum, 0),
            [info.rows]
          );
          return <Text css={{ color: '$primary1' }}>{total}</Text>;
        },
      },
    ],
    [fileId]
  );

  const submitterCorrectionProps = useDataTable({
    initialData: summaryData || [],
    initialColumns: submitterColumns,
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: false,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: false,
    showColumnVisibilityConfig: false,
    uniqueStorageId: 'EWS-submitter-table',
  });

  const elegibilityProps = useDataTable({
    initialData: elegibilityData || [],
    initialColumns: elegibilityColumns,
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: false,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: false,
    showColumnVisibilityConfig: false,
    uniqueStorageId: 'EWS-elegibility-table',
  });

  React.useEffect(() => {
    if (summaryData) {
      submitterCorrectionProps.setData(summaryData);
    }
  }, [summaryData]);

  React.useEffect(() => {
    if (elegibilityData) {
      elegibilityProps.setData(elegibilityData);
    }
  }, [elegibilityData]);

  return (
    <Card header="Submitter Corrections Required">
      <Card.Section>
        <Layout.Group grow>
          {submitterLoading ? (
            <LoadingScreen />
          ) : (
            <DataTable hideTitleHeader tableState={submitterCorrectionProps} />
          )}
        </Layout.Group>
        <Card header="Eligibility Analyst Corrections Required">
          <Card.Section>
            <Layout.Group grow>
              {elegibilityLoading ? (
                <LoadingScreen />
              ) : (
                <DataTable hideTitleHeader tableState={elegibilityProps} />
              )}
            </Layout.Group>
          </Card.Section>
        </Card>
      </Card.Section>
    </Card>
  );
};

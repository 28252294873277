import { getEEMSURL } from '@src/env.config';

export const CACHE_KEY = 'PRIME-EEMS-KEY';
// Function to get cached user details from localStorage
export const getCachedUserDetails = () => {
  if (typeof window === 'undefined') {
    return {
      platform: '',
      username: '',
      userType: '',
      policyNbrs: '',
    };
  }
  const cachedUserDetails: any = localStorage.getItem(CACHE_KEY);

  const userDetails = cachedUserDetails ? JSON.parse(cachedUserDetails) : null;
  return {
    platform: userDetails?.portalType.toString() || '',
    username: userDetails?.username || '',
    userType: userDetails?.userType || '',
    policyNbrs: userDetails?.policyNbrs || '',
    isBroker: userDetails?.broker || false,
  };
};

export const getDetailLink = ({ data, isChild = false }) => {
  const { platform } = getCachedUserDetails();
  let detailURl = new URL(`${getEEMSURL()}rpt/detailView.do`);
  detailURl.searchParams.append('transSetId', data?.transSetId);
  detailURl.searchParams.append('transId', data?.transId);
  detailURl.searchParams.append('platform', platform);
  detailURl.searchParams.append('empId', data?.subscrId);
  if (!isChild) {
    detailURl.searchParams.append('transFileId', data?.transFileId);
  }
  return detailURl;
};

export const formatDate = (date) => {
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

  return `${month}/${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

/**
 * Gives the url along with has for different URL location
 * params - void
 */
export const getHelpHash = () => {
  const currentURI = window?.location?.href;
  if (currentURI) {
    if (currentURI.includes('procStat')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#File_Statistics.htm';
    } else if (currentURI.includes('allTrans')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#All_Transaction.htm';
    } else if (currentURI.includes('addTran')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#Add_Transaction.htm';
    } else if (currentURI.includes('chgTran')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#Change_Transactions.htm';
    } else if (currentURI.includes('subCorr')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#Cust_Assign_Corrections.htm';
    } else if (currentURI.includes('eligCorr')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#Eligibility_Analyst_Corrections_Required_Screen.htm';
    } else if (currentURI.includes('nochg')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#No_Change_Transactions.htm';
    } else if (currentURI.includes('TermAutoChg')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#Terminations_Auto_Cancels.htm';
    } else if (currentURI.includes('errWarSum')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#error_and_warning_summary.htm';
    } else if (currentURI.includes('fldchgSum')) {
      return 'help/R30/WebHelp/Gateway_Online_Help.htm#field_change_summary.htm';
    } else {
      return 'help/WebHelp/CO_Exist_online_Help.htm';
    }
  }
};

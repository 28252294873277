import { Text } from '@abyss/web/ui/Text';
import { getEEMSURL } from '@src/env.config';

export const ErrorPage = ({ error }) => {
  return (
    <Text
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
      }}
    >
      <br />
      <div>
        <a href={`${getEEMSURL()}home.do`}>
          <img src="/Duckwaddle.gif" style={{ height: '30em' }} />
        </a>
      </div>
      <Text
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          fontWeight: 'bold',
          fontSize: '$lg',
        }}
      >
        {typeof error === 'string'
          ? error || 'Sorry the Token has expired! Please try again'
          : error?.message || 'Sorry the Token has expired! Please try again'}
      </Text>
    </Text>
  );
};

import { Table } from '@abyss/web/ui/Table';
import { ProcessingStatItem } from '../ProcessingStatsItem';

export const RecordCount = ({ procStatsList }) => {
  return (
    <ProcessingStatItem title="Record Count">
      <Table.Container>
        <Table.TableBody>
          <Table.Row>
            <Table.Cell>
              <b>Subscribers on File </b>
            </Table.Cell>
            <Table.Cell>{procStatsList?.[0]?.subscrFlCount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Dependents on File </b>
            </Table.Cell>
            <Table.Cell>{procStatsList?.[0]?.depFlCount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Total Records on File</b>{' '}
            </Table.Cell>
            <Table.Cell>{procStatsList?.[0]?.totalRecordsonFile}</Table.Cell>
          </Table.Row>
        </Table.TableBody>
      </Table.Container>
    </ProcessingStatItem>
  );
};

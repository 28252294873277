import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { Provider } from 'react-redux';
import { appStore } from './store/AppStore';
import { Routes } from './routes';
import { Styles } from './styles/Styles';

const theme = createTheme('optum');
const router = createRouter(Routes);

export const browser = () => {
  return (
    <Provider store={appStore}>
      <Styles>
        <AbyssProvider theme={theme} router={router} />
      </Styles>
    </Provider>
  );
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-card-title, .abyss-table-styled-header, .abyss-table-header-data-container':
    {
      // backgroundColor: '$info1',
      backgroundColor: '$primary1',
      color: '$white',
    },
  '.abyss-table-styled-header': {
    height: 'auto',
    background: '$primary1',
    '.abyss-table-header-data-container': {
      color: '$white',
    },
  },
});

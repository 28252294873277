import React from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Card } from '@abyss/web/ui/Card';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { useGetCustCorrectQuery } from '@src/store/services/ParentServices';
import { useGetChildCustCorrectQuery } from '@src/store/services/ChildServices';
import { getDetailLink } from '@src/utils';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const SubmitterCorrections = ({
  fileId: transFileId,
  setId: txnSetId,
  errorType: m_errType,
  isChild,
  fileDetail,
}) => {
  const { data: summaryData, isLoading } = isChild
    ? useGetChildCustCorrectQuery({
        txnSetId,
        m_errType,
      })
    : useGetCustCorrectQuery({
        transFileId,
        m_errType,
      });

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Severity',
        accessor: 'severityCode',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Policy Number',
        accessor: 'polNum',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'Subscriber ID',
        accessor: 'subscrId',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 80,
        minWidth: 80,
      },
      {
        Header: 'Name',
        accessor: 'name',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Rel',
        accessor: 'relationship',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'DOB',
        accessor: 'dob',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
      },
      {
        Header: 'Type',
        accessor: 'type',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'Status',
        accessor: 'stsCode',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Error Name',
        accessor: 'warningName',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
      },
      {
        Header: 'Customer Data',
        accessor: 'custData',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 75,
        minWidth: 75,
      },
      {
        Header: 'Source Data',
        accessor: 'sourceData',
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        width: 70,
        minWidth: 70,
      },
      {
        Header: 'Detail View',
        accessor: 'detailView',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Customer Name',
        width: 80,
        minWidth: 80,

        Cell: ({ row }) => {
          const { fileRecieveDate, customerName, custSubGroup } = fileDetail;
          const { transSetId, subscrId, transId, name } = row?.original || {};

          const sanitizedName = name?.replace(/,/g, ' ');
          const sanitizedFileRecieveDate = fileRecieveDate.replace(/\//g, '-');
          const nameOrGroup =
            (customerName?.trim().length > 0 ? customerName : custSubGroup) ??
            custSubGroup;

          const detailViewLink = `/home/detailView/${transSetId}/${subscrId}/${transId}/${sanitizedName}/${sanitizedFileRecieveDate}/${nameOrGroup}`;

          // const detailViewLinkNew = `/home/detailView/${row?.original?.transSetId}/${row?.original?.subscrId}/${row?.original?.transId}`;
          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              <Link size="10px" target="_blank" href={detailViewLink}>
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail New</strong>
                </Button>
              </Link>
              <Link
                size="10px"
                href={getDetailLink({ data: row?.original, isChild })}
              >
                <Button
                  variant="outline"
                  size="$sm"
                  css={{
                    fontSize: '12px',
                  }}
                >
                  <strong>Detail View</strong>
                </Button>
              </Link>
            </Flex>
          );
        },
      },
    ];
  }, []);

  const submitterCorrectionProps = useDataTable({
    initialData: summaryData || [],
    initialColumns: columns,
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: false,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
    showColumnVisibilityConfig: false,
    uniqueStorageId: 'data-table-usage',
  });

  React.useEffect(() => {
    if (summaryData) {
      submitterCorrectionProps.setData(summaryData);
    }
  }, [summaryData]);

  return (
    <Card header="Submitter Corrections Required">
      <Card.Section>
        <Layout.Group grow>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <DataTable hideTitleHeader tableState={submitterCorrectionProps} />
          )}
        </Layout.Group>
      </Card.Section>
    </Card>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Layout } from '@abyss/web/ui/Layout';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import type { RootState } from '../../store/AppStore';
import { FilesListTable } from './FilesListTable';
import { useForm } from '@abyss/web/hooks/useForm';
import { useLazyGetAllParentFilesQuery } from '../../store/services/ParentServices';
import { useSelector } from 'react-redux';

const defaultValues = {
  searchFor: '',
  searchType: 'subId',
  fileProcessingStatus: '',
};

export const Home = () => {
  const [trigger, { data: parentFilesList, isFetching, isError }] =
    useLazyGetAllParentFilesQuery();
  const [parentFilesCurrent, setParentFilesDefault] = useState(Array<any>);
  let debounceTime = useRef<null | ReturnType<typeof setTimeout>>(null);
  var parentFiles = useSelector((state: RootState) => state.files.value);
  console.log('Files list', parentFiles);
  const form = useForm({
    defaultValues,
  });

  useEffect(() => {
    const value = sessionStorage.getItem('filter')
      ? JSON.parse(sessionStorage.getItem('filter') || '') || defaultValues
      : defaultValues;
    trigger(value);
    form.setValue('searchFor', value?.searchFor);
    form.setValue('searchType', value?.searchType);
    form.setValue('fileProcessingStatus', value?.fileProcessingStatus);
  }, []);

  useEffect(() => {
    if (parentFilesList) {
      const subscription = form.watch((formData) => {
        if (debounceTime.current) clearTimeout(debounceTime.current);
        debounceTime.current = setTimeout(() => {
          trigger(formData);
          sessionStorage.setItem('filter', JSON.stringify(formData));
        }, 500);
      });
      setParentFilesDefault(parentFilesList);
      //parentFiles = [...parentFilesList];

      return () => {
        if (debounceTime.current) clearTimeout(debounceTime.current);
        subscription.unsubscribe();
      };
    }
  }, [parentFilesList]);

  useEffect(() => {
    if (parentFiles) {

      setParentFilesDefault(parentFiles);
    }
  }, [parentFiles]);

  const onSubmit = (data) => {
    trigger(data);
  };

  const onClear = (e) => {
    e.preventDefault();
    trigger(defaultValues);
    form?.reset?.();
    sessionStorage.setItem('filter', JSON.stringify(defaultValues));
  };

  if (isError) {
    return <div>Loading error in parent files </div>;
  }

  return (
    <FormProvider className="home-search-form" state={form} onSubmit={onSubmit}>
      <Router.MetaTags title="Home" />
      <Layout.Stack
        alignLayout="center"
        css={{
          '.abyss-data-table-subheader-lower-container': {
            display: 'flex',
            alignItems: 'flex-start',
          },
          '.abyss-data-table-pagination-top-root': {
            '.abyss-data-table-pagination-top-sub-container, .abyss-pagination-results-root':
              {
                display: 'none',
              },
          },
        }}
      >
        <FilesListTable
          parentFilesList={parentFilesCurrent}
          form={form}
          onSubmit={onSubmit}
          onClear={onClear}
          isLoading={isFetching}
        />
      </Layout.Stack>
    </FormProvider>
  );
};

import React, { useState, useEffect } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { useGetChildFileDetailsQuery } from '@src/store/services/ChildServices';
import { FilterAndDownload } from './FilterAndDownload';
import { FileDetails } from './FileDetails';

import { ProcessingStats } from './ProcessingStats';
import { ErrorWarningSummary } from './ErrorWarningSummary';
import { SubmitterCorrections } from './SubmitterCorrections';
import { EligibilityAnalystCorrections } from './EligibilityAnalystCorrections';

import { ChangeTransaction } from './ChangeTransaction';
import { AddTransaction } from './AddTransaction';
import { NoChangeTransaction } from './NoChangeTransaction';

import { MemberOmission } from './MemberOmission/MemberOmission';
import { TermSubmitted } from './TermSubmitted/TermSubmitted';

import { FieldChangeSummaryChild } from './FieldChangeSummary/FieldChangeSummaryChild';
import { useGetFileDetailsQuery } from '../../store/services/ParentServices';

import { FieldChangeSummary } from './FieldChangeSummary';
import { AllTransactions } from './AllTransactions';

export const DEFAULT_REPORT_TYPE = 'procStat';
const initialFileDetail = {
  custSubGroup: '',
  fname: '',
  transFileId: '',
  fileRecieveDate: '',
  lastUpdateDate: '',
  prflVerNbr: '',
  processingStatus: '',
  policyCount: '',
  policyNumber: '',
  customerName: '',
};

const RenderReport = ({
  isChild,
  fileId,
  setId,
  reportType,
  fieldCode,
  onChildValueChange,
  procStatus,
  fileDetail,
}) => {
  switch (reportType) {
    case 'subCorr': {
      return (
        <SubmitterCorrections
          errorType={fieldCode}
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    case 'eligCorr': {
      return (
        <EligibilityAnalystCorrections
          fileId={fileId}
          errorType={fieldCode}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    case 'errWarSum': {
      return (
        <ErrorWarningSummary fileId={fileId} isChild={isChild} setId={setId} />
      );
    }
    case 'fldchgSum': {
      return fieldCode ? (
        <FieldChangeSummaryChild
          fileId={fileId}
          fieldCode={fieldCode}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      ) : (
        <FieldChangeSummary
          fileId={fileId}
          reportType={reportType}
          isChild={isChild}
          setId={setId}
        />
      );
    }
    case 'allTrans': {
      return (
        <AllTransactions
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          procStatus={procStatus}
          fileDetail={fileDetail}
        />
      );
    }
    case 'procStat': {
      return (
        <ProcessingStats
          fileId={fileId}
          onChildValueChange={onChildValueChange}
          isChild={isChild}
          setId={setId}
        />
      );
    }
    case 'nochg': {
      return (
        <NoChangeTransaction
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    case 'chgTran': {
      return (
        <ChangeTransaction
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    case 'addTran': {
      return (
        <AddTransaction
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    case 'memberOM': {
      return <MemberOmission fileId={fileId} fileDetail={fileDetail} />;
    }
    case 'TermAutoChg': {
      return (
        <TermSubmitted
          fileId={fileId}
          isChild={isChild}
          setId={setId}
          fileDetail={fileDetail}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const Reports = ({ isChild = false }) => {
  const { fileId, setId, fieldCode, reportType } = useRouter().getRouteParams();
  const [fileDetail, setFileDetail] = useState(initialFileDetail);
  const { data: fileDetailsList } = isChild
    ? useGetChildFileDetailsQuery(setId)
    : useGetFileDetailsQuery(fileId);

  useEffect(() => {
    if (fileDetailsList) {
      setFileDetail({
        custSubGroup: fileDetailsList?.[0]?.sbmtGrpNm,
        fname: fileDetailsList?.[0]?.flNm,
        transFileId: isChild
          ? fileDetailsList?.[0]?.transSetId
          : fileDetailsList?.[0]?.transFileId,
        fileRecieveDate: fileDetailsList?.[0]?.flRecvDttm,
        lastUpdateDate: isChild
          ? fileDetailsList?.[0]?.transSetProcDt
          : fileDetailsList?.[0]?.lastUpdtDT,
        prflVerNbr: fileDetailsList?.[0]?.prflVerNbr,
        processingStatus: fileDetailsList?.[0]?.stsTxt,
        policyCount: `${fileDetailsList?.[0]?.totalcomcnt} out of ${fileDetailsList?.[0]?.totalcnt}`,
        policyNumber: fileDetailsList?.[0]?.polNbr,
        customerName: fileDetailsList?.[0]?.custNm,
      });
    }
  }, [fileDetailsList, isChild]);

  const router = useRouter();

  const handleReportChange = (report) => {
    router.navigate(
      `/home/${isChild ? 'child-reports' : 'reports'}/${fileId}${
        isChild ? `/${setId}` : ''
      }/${report}`
    );
  };

  return (
    <Card header="">
      <Card.Section>
        <FilterAndDownload
          setId={setId}
          fileId={fileId}
          reportType={reportType}
          fileDetail={fileDetail}
          onChange={handleReportChange}
          isChild={isChild}
        />
        <br />
        <FileDetails fileDetail={fileDetail} isChild={isChild} />
        <br />
        <br />
        <RenderReport
          isChild={isChild}
          fileId={fileId}
          setId={setId}
          reportType={reportType}
          fieldCode={fieldCode}
          procStatus={fileDetailsList?.[0]?.stsTxt}
          onChildValueChange={handleReportChange}
          fileDetail={fileDetail}
        />
      </Card.Section>
    </Card>
  );
};

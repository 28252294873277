import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { getCachedUserDetails } from '@src/utils';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { getServiceURL } from '@src/env.config';

const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
  async ({ url, method, data, params: requestParams }) => {
    try {
      let BASE_URL = `${getServiceURL()}`;
      Axios.defaults.baseURL = BASE_URL;
      const { platform, username, userType, policyNbrs } =
        getCachedUserDetails();
      let { isAuth, ...params } = requestParams;
      if (!isAuth) {
        params = {
          ...params,
          [params?.searchType === 'custName' ? 'tab' : 'platform']: platform,
          username,
          userType,
        };
      }
      const result = await Axios({
        url,
        method,
        data: policyNbrs,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error,
      };
    }
  };

export const apiService = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

import React, { useState, useEffect } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Link } from '@abyss/web/ui/Link';
import { Flex } from '@abyss/web/ui/Flex';

import { useGetchildFilesQuery } from '../../../store/services/ParentServices';
import { DEFAULT_REPORT_TYPE } from '@src/components/Reports/Reports';
import { LoadingScreen } from '@src/common/LoadingScreen';
import { getCachedUserDetails } from '@src/utils';

export const PolicyListTable = (prop) => {
  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    const { userType } = getCachedUserDetails();
    setIsInternal(userType === 'I');
  }, []);

  const { fileID, form } = prop;
  const {
    data: childFilesList,
    isLoading,
    isError,
  } = useGetchildFilesQuery({ transFileId: fileID, ...form.getValues() });
  var statusListArr = [
    'RECEIVED',
    'REPROCESSING ALL',
    'CANCELLED',
    'REPR SENT TO PRIME',
    'TRANSACTION_SENT_TO_PRIME',
    'FAILED',
    'PROCESSING',
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: 'Set ID',
        accessor: 'id',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'submiterGroupName',
        minWidth: 200,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Policy Number',
        accessor: 'policyNbr',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Received',
        accessor: 'fileRecvDate',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Status',
        accessor: 'fileStatus',
        minWidth: 250,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
        minWidth: 400,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Options',
        accessor: '',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        label: 'Reports',
        Cell: ({ row }) => {
          console.log('Policy table row', row);
          const isStatus = statusListArr.includes(
            row?.values?.fileStatus?.trim()
          );

          return (
            <Flex
              css={{
                gap: '10px',
              }}
            >
              {isStatus == false && (
                <>
                  <Link
                    href={`/home/child-reports/${row.original.fileID}/${row.values.id}/${DEFAULT_REPORT_TYPE}`}
                  >
                    Rprt
                  </Link>
                </>
              )}
              {isInternal ? (
                <>
                  |
                  <Link
                    href={`/home/monitor/${row.values.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mntr
                  </Link>
                </>
              ) : null}
            </Flex>
          );
        },
      },
    ],
    [isInternal]
  );

  const dataTableProps = useDataTable({
    initialData: [],
    initialColumns: columns,
    showColumnVisibilityConfig: false,
    showPagination: false,
    uniqueStorageId: 'data-table-usage',
  });

  useEffect(() => {
    if (childFilesList) {
      dataTableProps.setData(
        childFilesList.filter((item) => item.fileName.includes('.pcf'))
      );
    }
  }, [childFilesList]);

  useEffect(() => {
    dataTableProps.setColumns(columns, true);
  }, [columns]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error loading Policy files </div>;
  }

  return (
    <React.Fragment>
      <DataTable hideTitleHeader tableState={dataTableProps} />
    </React.Fragment>
  );
};
